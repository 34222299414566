import {createTheme, responsiveFontSizes} from "@mui/material/styles";

let theme = createTheme({
    typography: {
        body1: {
            // fontWeight: 500,
            color: "rgba(33,36,44,.84)",
            fontSize: "20px",
            lineHeight: 1.4,
            fontFamily: "PT Sans,Helvetica,Arial,sans-serif",
            // marginBottom: "25px"
        },
        h3: {
            fontWeight: 700,
            color: "#21242c",
            fontSize: "33px",
            lineHeight: 1.1,
            fontFamily: "PT Sans,Helvetica,Arial,sans-serif",
            // marginBottom: "25px"
        },
        h1: {
            fontWeight: "bold",
            color: "#21242c",
            fontSize: "50px",
            lineHeight: 1.15,
            fontFamily: "PT Sans,Helvetica,Arial,sans-serif",
            // marginBottom: "25px"
        },
        subtitle1: {
            fontWeight: "300",
            color: "#21242c",
            fontSize: "25px",
            lineHeight: 1.15,
            fontFamily: "PT Sans,Helvetica,Arial,sans-serif",
            // marginBottom: "25px"
        },
    },
});
theme = responsiveFontSizes(theme);
export default theme;