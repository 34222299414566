import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";

import INFO from "../data/user";
import SEO from "../data/seo";

import "./styles/projects.css";
import theme from "../theme";
import {ThemeProvider} from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Basictable from "../table"
import BasicList from "../Basic List";

const StorePickup = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const currentSEO = SEO.find((item) => item.page === "projects");

	return (
		<React.Fragment>
			<ThemeProvider theme={theme}>
				<Helmet>
					<title>{`Projects | ${INFO.main.title}`}</title>
					<meta name="description" content={currentSEO.description}/>
					<meta
						name="keywords"
						content={currentSEO.keywords.join(", ")}
					/>
				</Helmet>

				<div className="page-content">
					<NavBar active=""/>
					<div className="content-wrapper">
						<div className="projects-logo-container">
							<div className="projects-logo">
								<Logo width={46}/>
							</div>
						</div>
						<div className="projects-container">

							<Grid container spacing={3}>
								<Grid item xs></Grid>
								<Grid item xs={9}>


								<Avatar
									alt="MEC Logo"
									src="./MEC_logo.png"
									sx={{ width: 60, height: 60}}
								/>

									<Typography variant="h1" marginBottom={"25px"}> Store Pick Up (SPU) Usability Test and Research </Typography>

									<Typography variant="h3" marginBottom={"25px"} >The Background</Typography>

									<Typography variant="body1" marginBottom={"25px"}>MEC launched pick up in store in 2 hours features in January 2023. However, some items can not be picked up within 2 hours due to inventory errors or large units need longer processing times. MEC Website has received a lot of customers complaints about the actual pick up time was way more than 2 hours and the new feature was misleading. Therefore, MEC design team recalled this feature after 2 week of launching, and decide to redesign the idea. Before launching this new feature, The MEC Web design team has not done any usability test.</Typography>
									{/*<Typography variant="body1" >Only one specific flow and one specific UI to be able to edit desired SPU location  </Typography>							*/}

									<Typography variant="h3" marginBottom={"25px"} >The Problem</Typography>
									<Typography variant="body1" marginBottom={"25px"}>Customers are confused about the store pick up icon and the pick up timeline.</Typography>

									<Typography variant="h3" marginBottom={"25px"} >Goals and Objectives</Typography>
									<Typography variant="body1" marginBottom={"25px"}>1. To identify and discover the problem of the store pick up check out experience by users/participants.</Typography>
									<Typography variant="body1" marginBottom={"25px"}> 2. To observe how the user's behaviour and interaction with MEC website both on laptop and mobile </Typography>
									<Typography variant="body1" marginBottom={"25px"}>3. To identify and discover the problem of MEC website by users/participants.</Typography>


									<Typography variant="h3" marginBottom={"25px"} >Usability Testing Process</Typography>
									<Typography variant="body1" marginBottom={"25px"}> Define goals and target customers. </Typography>
									<Typography variant="body1" marginBottom={"25px"}> Customers who have shopped at the MEC website </Typography>
									<Typography variant="body1" marginBottom={"25px"}> Recruit Paticpants. sending marketing emails</Typography>
									<Typography variant="body1" marginBottom={"25px"}> Make a Test Script (Pre-Test interview, introduction, Scenario and Tasks, Post-Test Interview)</Typography>
									<Typography variant="body1" marginBottom={"25px"}> Conduct the remote test</Typography>
									<Typography variant="body1" marginBottom={"25px"}> Analysze the test and summarize the report</Typography>

									<Typography variant="h3" marginBottom={"25px"} >Roles</Typography>
									<Typography variant="body1" marginBottom={"25px"}> Moderator, Note Taker/Observer and Time Keeper (My team member Nic and I are taking turns to be moderator and observer</Typography>

									<Typography variant="h3" marginBottom={"25px"} >Scenario and Task</Typography>

									<Typography variant="h3" marginBottom={"25px"} >Findings</Typography>


									<BasicList></BasicList>

									{/*<Typography variant="subtitle1"> Research Method</Typography>*/}
									{/*<Typography variant="body1" > Qualitative Moderate</Typography>*/}

									{/*/!*<Typography variant="subtitle1"> Finance</Typography>*!/*/}
									{/*<Typography variant="body1" > $50 MEC Gift Card</Typography>*/}

                                    <Basictable></Basictable>




							{/*<div className="subtitle projects-subtitle">*/}
							{/*	I've worked on a variety of projects over the years*/}
							{/*	and I'm proud of the progress I've made. Many of*/}
							{/*</div>*/}

							{/*<div className="subtitle projects-subtitle">*/}
							{/*	I've worked on a variety of projects over the years*/}
							{/*	and I'm proud of the progress I've made. Many of*/}
							{/*	others to explore and contribute to. If you're*/}
							{/*</div>*/}



								</Grid>
								<Grid item xs></Grid>
							</Grid>


							<div className="all-projects-container">
								{/*<div className="all-projects-project">*/}
								{/*	<Project*/}
								{/*		// logo={"olivia.jpg"}*/}
								{/*		title={"this is my title"}*/}
								{/*		description={"this is a description"}*/}
								{/*		// linkText={project.linkText}*/}
								{/*		link={"/about"}*/}
								{/*	/>*/}
								{/*</div>*/}

								{/*<div className="all-projects-project">*/}
								{/*	<Project*/}
								{/*		// logo={"olivia.jpg"}*/}
								{/*		title={"this is my title"}*/}
								{/*		description={"this is a description"}*/}
								{/*		// linkText={project.linkText}*/}
								{/*		link={"/about"}*/}
								{/*	/>*/}
								{/*</div>*/}
							</div>

							{/*<div className="projects-list">*/}
							{/*	<AllProjects />*/}
							{/*</div>*/}
						</div>
						<div className="page-footer">
							<Footer/>
						</div>
					</div>
				</div>
			</ThemeProvider>
		</React.Fragment>
	);
};

export default StorePickup;
