import React, {useEffect} from "react";
import {Helmet} from "react-helmet";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import CancelIcon from '@mui/icons-material/Cancel';
import INFO from "../data/user";
import SEO from "../data/seo";
import ListItemIcon from '@mui/material/ListItemIcon';

import "./styles/projects.css";
import Grid from '@mui/material/Grid';
import {
    ThemeProvider,
} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import theme from "../theme"
import {CheckCircleRounded, ReportProblemRounded} from "@mui/icons-material";
import StandardImageList from "../ImageList";


const JJBeanProject = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const currentSEO = SEO.find((item) => item.page === "projects");

    return (
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <Helmet>
                    <title>{`Projects | ${INFO.main.title}`}</title>
                    <meta name="description" content={currentSEO.description}/>
                    <meta
                        name="keywords"
                        content={currentSEO.keywords.join(", ")}
                    />
                </Helmet>

                <div className="page-content">
                    <NavBar active=""/>
                    <div className="content-wrapper">
                        <div className="projects-logo-container">
                            <div className="projects-logo">
                                <Logo width={46}/>
                            </div>
                        </div>
                        <div className="projects-container">

                            <Grid container spacing={3}>
                                <Grid item xs></Grid>
                                <Grid item xs={9}>
                                    <Typography variant="h1" marginBottom={"25px"}>JJ Bean Cafe Ordering App</Typography>

                                        <Typography variant="subtitle1"> Date: Jan. 2023</Typography>
                                        <Typography variant="subtitle1" marginBottom={"25px"}> Role: User Research, Wireframing, Prototyping, App
                                            Design</Typography>


                                    <Typography variant="body1" marginBottom={"25px"}> JJ Benas is local cafe, I created this app to help
                                        people place and pick up orders
                                        during peak hours so that customers can avoid long lineups. It helps the
                                        business
                                        improve the efficiency of ordering and overall customer satisfaction.
                                        <strong>The goal is to reduce the average ordering time from 5 mins to 3.5 mins..</strong> </Typography>

                                    <Typography variant="h3" marginBottom={"25px"} >Problem Statement</Typography>
                                    <Typography variant="body1" marginBottom={"25px"}> Customers are waiting in line for too long for ordering.
                                        The business is potential losing customers.</Typography>
                                    <Typography variant="h3" >Pain Point</Typography>

                                    <List sx={{ width: '100%', maxWidth: 660, bgcolor: 'background.paper' }}>
                                        <ListItem>
                                                <ListItemIcon>
                                                    <CancelIcon color={"error"} fontSize={"large"} />
                                                </ListItemIcon>
                                            <ListItemText primary="Time" secondary="Woking adults around doens't have time line up for orders" />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CancelIcon color={"error"} fontSize={"large"} />
                                            </ListItemIcon>
                                            <ListItemText primary="Trend" secondary="Youner generation prefer digital experiences, they like to order and pick up" />
                                        </ListItem>                           <ListItem>
                                        <ListItemIcon>
                                            <CancelIcon color={"error"} fontSize={"large"} />
                                        </ListItemIcon>
                                        <ListItemText primary="Fact" secondary="E-gift cards can't be scanned and customers can not check the balacne in store" />
                                    </ListItem>
                                    </List>

                                    <Typography variant="h3" >Define Problem</Typography>

                                    <List sx={{ width: '100%', maxWidth: 500, bgcolor: 'background.paper' }}>
                                        <ListItem>
                                            <ListItemIcon>
                                                <ReportProblemRounded color={"warning"} fontSize={"large"} />
                                            </ListItemIcon>
                                            <ListItemText primary="Time" secondary="No ordering APP availiable for JJ Beans. " />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <ReportProblemRounded color={"warning"} fontSize={"large"} />
                                            </ListItemIcon>
                                            <ListItemText primary="Trend" secondary="No Visible Menu in the store" />
                                        </ListItem>                           <ListItem>
                                        <ListItemIcon>
                                            <ReportProblemRounded color={"warning"} fontSize={"large"} />
                                        </ListItemIcon>
                                        <ListItemText primary="Fact" secondary="E-gift cards cannot be scanned at the register." />
                                    </ListItem>
                                    </List>

                                    <Typography variant="h3" >The Goal</Typography>

                                    <List sx={{ width: '100%', maxWidth: 500, bgcolor: 'background.paper' }}>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CheckCircleRounded color={"success"} fontSize={"large"} />
                                            </ListItemIcon>
                                            <ListItemText primary="Time" secondary="Imrpove the ordering effiency, reduce the average ordering time from 5 mins to 3.5 mins." />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CheckCircleRounded color={"success"} fontSize={"large"} />
                                            </ListItemIcon>
                                            <ListItemText primary="Trend" secondary="Allow users to view menu in the app" />
                                        </ListItem>                           <ListItem>
                                        <ListItemIcon>
                                            <CheckCircleRounded color={"success"} fontSize={"large"} />
                                        </ListItemIcon>
                                        <ListItemText primary="Fact" secondary="Allow users to pay by E gift card via the APP and check the balance" />
                                    </ListItem>
                                    </List>



                                    {/*<Avatar*/}
                                    {/*    alt="Remy Sharp"*/}
                                    {/*    src="/static/images/avatar/1.jpg"*/}
                                    {/*    sx={{ width: 56, height: 56 }}*/}
                                    {/*/>*/}

                                    <StandardImageList></StandardImageList>


                                </Grid>
                                <Grid item xs></Grid>
                            </Grid>


                            {/*<Box sx={{ flexGrow: 1 }}>*/}
                            {/*	<Grid container spacing={3}>*/}
                            {/*		<Grid item xs={1}>*/}
                            {/*			<Item>xs=4</Item>*/}
                            {/*		</Grid>*/}
                            {/*		<Grid item xs={2}>*/}
                            {/*			<Item>xs=4</Item>*/}
                            {/*		</Grid>*/}
                            {/*		<Grid item xs={3}>*/}
                            {/*			<Item>xs=4</Item>*/}
                            {/*		</Grid>*/}
                            {/*		<Grid item xs={8}>*/}
                            {/*			<Item>xs=8</Item>*/}
                            {/*		</Grid>*/}
                            {/*	</Grid>*/}
                            {/*</Box>*/}




                            {/*<div className="subtitle projects-subtitle">*/}
                            {/*    Persona Sam*/}
                            {/*</div>*/}
                            {/*<div className="subtitle projects-subtitle">*/}
                            {/*    Age: 38*/}
                            {/*    Location: Vancouver*/}
                            {/*    Occupation: Finance Industry*/}
                            {/*    Family: Husband and dad lives in Vancouver*/}
                            {/*</div>*/}
                            {/*<div className="text_main">*/}
                            {/*    Frustrations: "I feel frustrated to wait in line for coffee during rush hours"*/}

                            {/*    "It is hard to do phone orders, The store is either too busy to pick up the phone or it*/}
                            {/*    is too noisy to communicate over the phone. I wish I can order ahead ."*/}
                            {/*</div>*/}
                            {/*<div className="text_main">*/}
                            {/*    Fresh and hot coffee within 5 mins from ordering to pick up in store.*/}

                            {/*    Know the ordering time in advance and pick up the coffee on time.*/}
                            {/*</div>*/}
                            {/*<div className="text_main">*/}
                            {/*    " I like my coffee fresh and hot, and I hate to wait in line during rush hours "*/}
                            {/*</div>*/}
                            {/*<div className="text_main">*/}
                            {/*    Sam is a businessman and a coffee lover. Time is money, Sam finds it is time-consuming*/}
                            {/*    to wait in line to order. Phone orders work well due to no one picking up the phone or*/}
                            {/*    miscommunication. Sam is particular about his coffee, he needs it hot and fresh. He*/}
                            {/*    measures the time he can grab coffee.*/}
                            {/*</div>*/}

                            {/*<div className="subtitle projects-subtitle">*/}
                            {/*    Persona Emily*/}
                            {/*</div>*/}
                            {/*<div className="subtitle projects-subtitle">*/}
                            {/*    Age: 21*/}
                            {/*    Location: Vancouver*/}
                            {/*    Occupation: University Student*/}
                            {/*    Family: Single, lives alone*/}
                            {/*</div>*/}
                            {/*<div className="text_main">*/}
                            {/*    Frustrations: "I wish I could do everything online! "*/}

                            {/*    "I hate waiting in line for coffee and returning back to work late."*/}
                            {/*</div>*/}
                            {/*<div className="text_main">*/}
                            {/*    Minimize the Wait time for ordering*/}

                            {/*    Minimize in-person interaction and improve the accuracy of the group ordering.*/}
                            {/*</div>*/}
                            {/*<div className="text_main">*/}
                            {/*    " I like to use an ordering APP rather than talking to a barista to explain the order*/}
                            {/*    details. "*/}
                            {/*</div>*/}
                            {/*<div className="text_main">*/}
                            {/*    Emily is a student who also works part-time near JJ Beans. Emily knows the coffee she*/}
                            {/*    likes and always orders the same drinks. Emily usually goes to JJ Beans during her*/}
                            {/*    break, and sometimes group ordering for her co-workers too. She thinks it is*/}
                            {/*    time-consuming to line up and explain each customization order to Barista.*/}
                            {/*</div>*/}

                            <img
                                src="./design_pic/persona_emily.png"
                                alt="about"
                                className="about-image marginBottom=35px"
                            />

                            <img
                                src="./design_pic/persona_sam.png"
                                alt="about"
                                className="about-image"
                            />

                            <Typography variant="h3" marginBottom={"25px"} >Paper Wireframe</Typography>
                            <img
                                src="./design_pic/JJ_paper_wire.jpg"
                                alt="about"
                                className="about-image"
                            />

                            <Typography variant="h3" marginBottom={"25px"} >Digital Wireframe</Typography>
                            <img
                                src="./design_pic/JJ_wireframe.jpg"
                                alt="about"
                                className="about-image"
                            />






                            <div className="all-projects-container">

                                {/*<div className="all-projects-project">*/}
                                {/*    <Project*/}
                                {/*        // logo={"olivia.jpg"}*/}
                                {/*        title={"this is my title"}*/}
                                {/*        description={"this is a description"}*/}
                                {/*        // linkText={project.linkText}*/}
                                {/*        link={"/about"}*/}
                                {/*    />*/}
                                {/*</div>*/}

                                {/*<div className="all-projects-project">*/}
                                {/*    <Project*/}
                                {/*        // logo={"olivia.jpg"}*/}
                                {/*        title={"this is my title"}*/}
                                {/*        description={"this is a description"}*/}
                                {/*        // linkText={project.linkText}*/}
                                {/*        link={"/about"}*/}
                                {/*    />*/}
                                {/*</div>*/}
                            </div>

                            {/*<div className="projects-list">*/}
                            {/*	<AllProjects />*/}
                            {/*</div>*/}
                        </div>

                        <div className="page-footer">
                            <Footer/>
                        </div>
                    </div>
                </div>
            </ThemeProvider>
        </React.Fragment>
    );
};

export default JJBeanProject;
