import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";

import INFO from "../data/user";
import SEO from "../data/seo";

import "./styles/projects.css";
import Project from "../components/projects/project";

const Projects = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const currentSEO = SEO.find((item) => item.page === "projects");

	return (
		<React.Fragment>
			<Helmet>
				<title>{`Projects | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content">
				<NavBar active="projects" />
				<div className="content-wrapper">
					<div className="projects-logo-container">
						<div className="projects-logo">
							<Logo width={46} />
						</div>
					</div>
					<div className="projects-container">

						<div className="title projects-title">
							Project A
						</div>

						<div className="subtitle projects-subtitle">
							I've worked on a variety of projects over the years
							and I'm proud of the progress I've made. Many of
						</div>

						<div className="subtitle projects-subtitle">
							I've worked on a variety of projects over the years
							and I'm proud of the progress I've made. Many of
						</div>

						<div className="subtitle projects-subtitle">
							I've worked on a variety of projects over the years
							and I'm proud of the progress I've made. Many of
							others to explore and contribute to. If you're
						</div>

						<div className="all-projects-container">

							<div className="all-projects-project">
								<Project
									// logo={"olivia.jpg"}
									title={"this is my title"}
									description={"this is a description"}
									// linkText={project.linkText}
									link={"/about"}
								/>
							</div>

							<div className="all-projects-project">
								<Project
									// logo={"olivia.jpg"}
									title={"this is my title"}
									description={"this is a description"}
									// linkText={project.linkText}
									link={"/about"}
								/>
							</div>
						</div>

						{/*<div className="projects-list">*/}
						{/*	<AllProjects />*/}
						{/*</div>*/}
					</div>
					<div className="page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Projects;
