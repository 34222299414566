const INFO = {
	main: {
		title: "Olivia S.' Portfolio",
		name: "Olivia Shen",
		email: "oliviashen1209@gmail.com",
		logo: "../logoshen.jpg",
	},

	socials: {
		twitter: "https://twitter.com/",
		github: "https://github.com/",
		linkedin: "https://linkedin.com/in/olivia-shen-386081b9",
		instagram: "https://instagram.com/",
		stackoverflow: "https://stackoverflow.com/",
		facebook: "https://facebook.com/",
	},

	homepage: {
		title: "My name is Olivia Shen.",
		description:
			"I am a UX/UI designer, product designer and an inspiring artist. I enjoy solving complex problems and learning new skills.",
	},

	about: {
		name:"I’m Olivia Shen",
		title: "I am a UX/UI designer, and I like to solve problems and design for the future.",
		description:
			"I'm a UX UI designer. I enjoy finding problems in life, providing solutions, improving things, and making our life easier!  If you're interested in any of the projects I've worked on, please feel free to reach out. Collaborating with others is a great way to learn and grow, and I'm always open to new ideas and feedback.",
	},

	articles: {
		title: "I'm passionate about pushing the boundaries of what's possible and inspiring the next generation of innovators.",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
	},

	projects: [
		{
			title: "JJ Bean Cafe Ordering App",
			description:
				"I created this app to help people place and pick up orders during peak hours so that customers can avoid long lineups. It helps the business improve the efficiency of ordering and overall customer satisfaction.",
			logo: "JJ_bean_icon.png",
			linkText: "Goto Project",
			link: "/JJ_bean_project",
		},

		{
			title: "Store Pick Up User Research",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "MEC_logo.png",
			linkText: "View Project",
			link: "/store_pickup",
		},

		{
			title: "Add to Bag Project",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "MEC_logo.png",
			linkText: "View Project",
			link: "add_to_bag",
		},

		{
			title: "Project 4",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://github.com",
		},

		{
			title: "Project 5",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://github.com",
		},
	],
};

export default INFO;
